.day .placeholder-wrapper {
    border-bottom: 1px solid $border-color;
    &.is-optional .is-request {
        div, span, label, .column, .currency, .deadline-date:not([class*="is-"]) { color: $gray-dark; }
        i { color: $gray; }
        .fa-warning { color: $orange; }
    }
    &.is-optional {
        .simple-menu i { color: $button-color; }
        .simple-menu-content i { color: white; }
    }
    &:last-child { border-bottom: 0 none; }
    &.is-selected { background-color: $pink; }
    &.is-edit-mode { z-index: 2; }


    .requests {
        background-color: white;
        .is-request {
            padding-left: 35px;
            border-top: 1px solid $border-color;
            border-bottom: 0 none;
        }

        .is-main {
            &:before {
                content: ' ';
                width: 18px;
                height: 18px;
                background-color: $green;
                position: absolute;
                top: 6px;
                left: 13px;
                border: 1px solid $gray;
                border-radius: 50%;
            }
            &:after {
                content: 'check';
                @include font-family-material;
                position: absolute;
                top: 0;
                left: 14px;
                color: white;
                font-size: 15px;
            }
        }
    }
}


.placeholder-view {
    min-height: 30px;
    position: relative;
    padding-right: 85px;
    border-bottom: 1px solid $border-color;
    line-height: 30px;
    display: flex;
    &:hover { background-color: $blue-lightest; }

    &.is-optional {
        div, span, label, .column, .currency, .deadline-date:not([class*="is-"]) { color: $gray-dark; }
        i { color: $gray; }
    }
    &.is-additional {
        div, span, label, .column, .currency, .deadline-date:not([class*="is-"]) { color: $additonal-color; }
        i { color: $gray-dark; }
    }
    &.has-no-route-description > div:not(.action-buttons){ opacity: 0.7; }
    &.is-selected { background-color: $pink; }

    & > div {
        width: 150px;
        padding: 0 12px;
        flex: none;
    }

    & > div:first-child  {
        width: 335px;
        flex: 1;
        @include ellipsis();
    }
    & > div:nth-child(2) { width: 131px; }
    & > div:nth-child(6) { width: 120px; }
    & > div:nth-child(8) { width: 131px; }
    .is-300 { width: 300px; }
    .is-250 { width: 250px; }
    .is-130 { width: 130px; }
    .D { width: 120px; }
    .is-100 { width: 100px; }
    .is-60  { width:  60px; }

    .action-buttons {
        width: auto;
        height: 100%;
        position: absolute;
        top: 0;
        right: 4px;
        padding: 0;
        align-items: center;
        justify-content: flex-end;
        display: flex;
        .simple-menu {
            opacity: 0;
            transition: opacity 0.2s ease-out;
            &.is-open { opacity: 1; }
        }

        .is-30 {
            width: 30px;
        }
    }
    &:hover .action-buttons .simple-menu { opacity: 1; }
    &:hover .group-icon { visibility: hidden; }

    .simple-menu { height: 100%; }

    .contingent-section {
        width: 120px;
        .fa-warning {
            margin-left: 5px;
            color: $orange;
            font-size: 12px;
            vertical-align: 0;
        }
    }

    .route-difficulty {
        height: 100%;
        position: relative;
        top: 0;
        margin-left: 5px;
        padding-top: 11px;
        display: inline-flex;
        .dot {
            width: 9px;
            height: 9px;
            margin-left: 2px;
            border: 1px solid $gray-darker;
            border-radius: 50%;
        }
        &.has-1 .dot:nth-child(1),
        &.has-2 .dot:nth-child(1),
        &.has-2 .dot:nth-child(2),
        &.has-3 .dot:nth-child(1),
        &.has-3 .dot:nth-child(2),
        &.has-3 .dot:nth-child(3) { background-color: $gray-darker; }
    }


    .is-full-cell {
        margin-left: -7px;
        select {
            border-top: 0 none;
            border-bottom: 0 none;
        }
    }

    .bp-deadline.is-editable {
        height: 100%;
        transform: translateX(-9px);
        border-top: 0;
        border-bottom:0;
    }

    .btn-warning{
        i {
            color: $blue-dark;
            &:hover { color: $blue-active; }
        }
        &.is-accent i { color: $link-color; }

        &.is-high i { color: $red-light; }
        &.is-medium i { color: $yellow; }
        &.is-off i {opacity: 0}
    }

    a {
        height: 100%;
        color: $link-color;
        display: inline-block;
        &:hover { text-decoration: underline; }
    }

    .concept-provider-btn {
        width: 18px;
        height: 18px;
        text-align: center;
        color: $text-color;
        font-size: 11px;
        font-weight: 600;
        z-index: 1;
        display: inline-block;
        border-radius: 50%;
        border: 1px solid $gray;
        transition: transform 0.2s ease-out;
        background-color: white;

        &.is-1 {
            background-color: $green;
        }
        &.is-2 {
            background-color: $pink_dark;
            color: white
        }
        &.is-3 {
            background-color: $orange;
            color: white
        }
        &.is-4 {
            background-color: $pink_light;
        }
        &.is-5 {
            background-color: white;
        }
        &:not(:first-child) {
            margin-left: 10px;
        }
    }


    &.has-requests {
        padding-left: 35px;
        border-bottom: 0 none;

        .nr-of-requests {
            width: 44px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;
            color: white;
            font-size: 11px;
            font-weight: 600;
            z-index: 1;
            display: inline-block;

            &:before {
                content: ' ';
                width: 18px;
                height: 18px;
                border-radius: 50%;
                border: 1px solid $gray;
                position: absolute;
                top: 7px;
                left: 13px;
                z-index: -1;
                transition: transform 0.2s ease-out;
            }
            &:hover:before { transform: scale(1.1); }

            &.is-high:before       { background-color: $pink_dark; }
            &.is-medium:before     { background-color: $blue-active; }
            &.is-medium-low:before { background-color: white; }
            &.is-medium-low { color: $gray-dark; }
            &.is-low:before        { background-color: $background-color; }
            &.is-low { color: $gray-dark; }
            &.is-ok:before {
                background-color: $green;
            }
        }
        &.is-optional .nr-of-requests:before { background-color: $gray; }
    }

    div.group-icon {
        width: 33px;
        height: 100%;
        background-color: $accent;
        position: absolute;
        top: 0;
        right: 0;
        padding: 0;
        text-align: center;
        &:after {
            width: 21px;
            position: relative;
            color: white;
            font-size: 20px;
            display: inline-block;
        }
        &.is-bike:after        { content: 'directions_bike'; @include font-family-material; }
        &.is-hiking:after      { content: 'landscape';       @include font-family-material; }
        &.is-culture:after     { content: 'account_balance'; @include font-family-material; }
        &.is-bus:after         { content: 'directions_bus';  @include font-family-material; }
        &.is-alternative:after { content: 'list_alt';        @include font-family-material; }
    }
}


.placeholders-actions {
    margin-bottom: 45px;
    .title {
        min-height: 48px;
        margin-top: -15px;
        margin-bottom: 15px;
        align-items: center;
        justify-content: space-between;
        color: $text-color;
        font-size: $font-size;
        display: flex;
        .material-icons { vertical-align: -4px; }
    }
    p {
        margin-bottom: 7px;
        color: $gray-dark;
        font-size: 11px;
    }
    & > div {
        margin-bottom: 15px;
        display: flex;
        &:last-child { margin-bottom: 0; }
    }

    .button {
        min-width: 170px;
        margin-left: 10px;

        &:first-child {
            margin-left: 0;
        }
    }
}


.day {
    .route-detail-spacing { margin-right: 25px; }


    // Warnings
    .placeholder-subcomponents {
        max-height: 200px;
        background-color: $blue-lightest;
        border-top: 1px solid $border-color;
        overflow: hidden;
        overflow-y: scroll;

        &.is-gray {
            background: $gray-lighter;
            color: $text-color;

            & > div {
                color: inherit;
                strong, button { color: inherit }
            }
        }

        &.slide-enter, &.slide-leave-to { max-height: 0; }

        & > div {
            min-height: 33px;
            padding: 2px 5px;
            border-bottom: 1px solid $border-color;
            color: $text-color;
            line-height: 29px;
            &:last-child { border-bottom: 0 none; }

            i { margin: 0 11px 0 8px; }
            strong { color: $text-color }
            button {
                padding-right: 15px;
                color: $text-color;
            }
        }
    }
    .placeholder-subcomponents.warnings i {
        color: $text-color;

        &.is-high { color: $red-light; }
        &.is-medium { color: $yellow; }
    }

    .warning-section {
        padding: 10px 0px;
    }

    .warning-title {
        font-weight: 600;
    }

    .warning-content {
        padding: 0px 40px;
    }

}
